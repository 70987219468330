<template>
  <div class="politique">
      <h3>Politique de protection et d'utilisations des données</h3>
      <p>Les informations recueillies vous concernant font l’objet d’un traitement destiné à : La Cité des Métiers, FFPC, MBG, DIP et DT.</p>
      <p>Pour la finalité suivante : Conseils d’orientation, statistiques et newsletters.</p>
      <p>Vous bénéficiez d’un droit d’accès, de rectification, de portabilité, d’effacement de celles-ci ou une limitation du traitement à tout moment et aussi lors de chaque réception de newsletters. </p>
      <p>Vous pouvez vous opposer au traitement des données vous concernant et disposez du droit de retirer votre consentement en vous adressant à <a href="mailto:info@quiz-metiers-transition-energetique.ch">info@quiz-metiers-transition-energetique.ch</a> ou en cliquant sur "se désabonner" en bas de chaque newsletter envoyée.</p>
      <p>Vous avez aussi la possibilité d’introduire une réclamation auprès d’une autorité de contrôle.</p>

        <bouton class="btn-fermer" v-on:click="closeBtn">Fermer</bouton>

    </div>
</template>

<script>
export default {
  name: 'politiqueConfidentialite',

  methods: {
    closeBtn () {
      this.$emit('closePolitique')
      console.log('clicked')
    }
  }
}

</script>

<style lang="scss">
  .politique {
    padding: 20px;
    background-color: lightgrey;
    margin: 20px 0;
    text-align: left;
    font-size: 0.8em;

    .btn-fermer {
      background-color: #000;
      color: #fff;
      padding: 5px 10px;
      cursor: pointer;

      &:hover {

      }
    }
  }
</style>
